export default function appointmentShape(date = null) {
  if (date) {
    let appointmentDate = `${new Date(date).toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })}`;
    let appointmentTime = `${new Date(
      date
    ).getHours()}:${new Date(date).getMinutes()}`;

    return { date: appointmentDate, time: appointmentTime }
  }
  else return null
}