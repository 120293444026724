import { i18n } from '@/plugins/i18n'
const { tc } = i18n.global;

import appointmentShape from "./Appointment"

export default function Setup(checking) {
  const appointment = appointmentShape(checking.AppointmentDateTime);

  const texts = {
    "occupant": tc('Checking.occupant', checking.Occupants.length)
  };

  return {
    appointment,
    texts
  };
}
